
import { mapGetters } from 'vuex'
import LogoSVG from '@/components/svg/LogoSVG.vue'
import TriSVG from '@/components/svg/detail/TriSVG.vue'
export default {
  components: { LogoSVG, TriSVG },
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      navtl: null,
      tl: null,
    }
  },
  computed: {
    ...mapGetters({
      navbar: 'getNavbar',
    }),
  },
  watch: {
    $route() {
      this.navtl.reverse()
    },
  },
  mounted() {
    this.navtl = this.$gsap.timeline({ paused: true, reversed: true })
    // this.navtl.to('body', { overflow: 'hidden' })
    // this.navtl.to(this.$refs.menu, { xPercent: -100, duration: 0.2 })
    this.navtl.to(this.$refs.menu, { x: 0, duration: 0.2 })
    this.navtl.to(this.$refs.open, { opacity: 0, duration: 0.1 }, '<')
    this.navtl.to(this.$refs.close, { opacity: 1, duration: 0.1 }, '>')
    this.navtl.from(
      this.$refs.menuLogo.$el,
      {
        opacity: 0,
        duration: 0.2,
      },
      '<'
    )
    const heading = new this.$SplitText(this.$refs.menuItem, { type: 'chars, words, lines' })
    this.navtl.from(
      heading.chars,
      {
        opacity: 0,
        stagger: {
          each: 0.05,
          from: 'start',
          grid: 'auto',
          ease: 'power2.inOut',
        },
      },
      '<'
    )

    this.tl = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
      },
    })
    this.tl.scrollTrigger.disable()

    this.tl.from(this.$refs.logo.$el, { opacity: 0, yPercent: -5, duration: 0.2 })
    this.tl.from(this.$refs.item, { opacity: 0, yPercent: -5, duration: 0.2, stagger: 0.05 }, '>')
    this.tl.from(this.$refs.tris.children, { opacity: 0, yPercent: -100, stagger: 0.1 }, '>')

    this.$gsap.ticker.add(this.hideLogo)

    this.$emit('sectionMounted')
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
    this.$gsap.ticker.remove(this.hideLogo)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
    open() {
      this.navtl.reversed() ? this.navtl.play() : this.navtl.reverse()
    },
    hideLogo() {
      window.scrollY > 16 ? this.$gsap.to(this.$refs.logo.$el, { translateX: -64, opacity: 0, duration: 0.1 }) : this.$gsap.to(this.$refs.logo.$el, { translateX: 0, opacity: 1, duration: 0.1 })
    },
  },
}
