
import NavBar from '@/components/structure/NavBar.vue'
import Footer from '@/components/structure/Footer.vue'
import meta from '~/mixins/meta'
export default {
  components: {
    NavBar,
    Footer,
    Sections: () => import('~/components/structure/Sections.vue'),
  },
  mixins: [meta],
  props: {
    page: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
}
